import {ISearchAddress} from "../interfaces/ISearchAddress";

export class SearchAddressService {
    protected PATH = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

    get = async(query: string, isStreet: boolean = true, city: string | undefined) => {
        const locations = isStreet ? [{fias_level: "7", city: city}] : [{fias_level: "4"}]
        const resp = await fetch(this.PATH, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Accept": "application/json",
                "Authorization": "Token a6174802e7dd9ef5d78b0c3c72845d4e42635299"
            },
            body: JSON.stringify({query, locations})
        })

        const json = await resp.json() as ISearchAddress

        return json
    }

}