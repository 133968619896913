// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import Page from '../Page/Page'
import classes from './Order.module.css'
import Arrow from '../../assets/images/arrow_cat.svg'
import { priceWithSpace } from '../../utils/priceWithSpace'
import clsx from 'clsx'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Placeholder from '../../assets/images/no_item.png'
import { getSalesPrice, getTotalDefaultPrice } from '../../utils/getTotalPrice'
import { CustomerService } from '../../api/CustomerService'
import { useLoaderData } from 'react-router-dom'
import { IDeliveryType } from '../../interfaces/DeliveryType'
import { IRetailPoint } from '../../interfaces/RetailPoint'
import { IOrder } from '../../interfaces/Order'
import { IAddress } from '../../interfaces/Address'
import { OrderService } from '../../api/OrderService'
import { SearchAddressService } from '../../api/SearchAddress'
import { ISearchAddressValue } from '../../interfaces/ISearchAddress'
import { ISettings } from '../../interfaces/Settings'
import { IItem, IItemModifier, IItemValue } from '../../interfaces/Item'
import { cartActions } from '../../store/reducers/cartReducer'
import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import useTinkoff from '../../hooks/useTinkoff'
import { ICustomer } from '../../interfaces/Customer'
import { IConfirmOrderResponse } from '../../interfaces/ITinkoffReceipt'

type PromiseType = {
    deliveryTypes: Array<Promise<IDeliveryType>>
    retailPoints: Array<Promise<IRetailPoint>>
    settings: Array<Promise<ISettings>>
    deliveryProduct: Promise<IItemValue>
}

const Order = () => {
    const { deliveryTypes, retailPoints, settings, deliveryProduct } =
        useLoaderData() as PromiseType

    const orderService = new OrderService()
    const searchAddress = new SearchAddressService()

    const cityBlockRef = useRef<HTMLDivElement>(null)
    const cityInputRef = useRef<HTMLDivElement>(null)
    const streetBlockRef = useRef<HTMLDivElement>(null)
    const streetInputRef = useRef<HTMLDivElement>(null)
    const streetRef = useRef<HTMLInputElement>(null)
    const loaderRef = useRef<HTMLDivElement>(null)

    const { cart, promocode, promocodePrice, withdrawBonuses } = useTypedSelector(
        (state) => state.cartReducer
    )
    const { user } = useTypedSelector((state) => state.userReducer)
    const [userBalance, setUserBalance] = useState(0)

    const [deliveryType, setDeliveryType] = useState<IDeliveryType | null>(null)
    const [deliveryTypesSync, setDeliveryTypesSync] = useState<Array<IDeliveryType>>([])
    const [filteredDeliveryTypes, setFilteredDeliveryTypes] = useState<Array<IDeliveryType>>([])

    const [retailPointsSync, setRetailPointsSync] = useState<Array<IRetailPoint>>([])
    const [filteredRetailPoints, setFilteredRetailPoints] = useState<Array<IRetailPoint>>([])
    const [retailPoint, setRetailPoint] = useState<string>()

    const [city, setCity] = useState<ISearchAddressValue | null>(null)
    const [cityInput, setCityInput] = useState<string>('')
    const [cities, setCities] = useState<Array<ISearchAddressValue>>([])

    const [street, setStreet] = useState<ISearchAddressValue | null>(null)
    const [streetInput, setStreetInput] = useState<string>('')
    const [streets, setStreets] = useState<Array<ISearchAddressValue>>([])

    const [house, setHouse] = useState<string>('')
    const [apartment, setApartment] = useState<string>('')
    const [deliveryP, setDeliveryP] = useState<IItem | null>(null)
    const [minDeliveryCost, setMinDeliveryCost] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const [kilbilTotalDiscounted, setKilbilTotalDiscounted] = useState<number | null>(null)
    const [kilbilTotal, setKilbilTotal] = useState<number | null>(null)

    const [comment, setComment] = useState('')

    const dispatch = useDispatch()

    useTinkoff()

    useEffect(() => {
        let isMount = true
        const getBalance = async () => {
            try {
                const api = new CustomerService()
                const balance = await api.getBalance()
                if (isMount) setUserBalance(balance)
            } catch (e) {
                console.log(e)
            }
        }
        getBalance().then()

        calcCart()

        return () => {
            isMount = false
        }
    }, [])

    useEffect(() => {
        if (loaderRef && loaderRef.current) {
            loaderRef.current.style.display = loading ? 'inline-block' : 'none'
        }
    }, [loading])

    useEffect(() => {
        if (deliveryP && deliveryCost()[1] > 0) {
            dispatch(
                cartActions.addDelivery({
                    _id: v4(),
                    amount: 1,
                    product: deliveryP,
                    modifiers: deliveryCost()[2],
                })
            )
        } else if (deliveryP && !deliveryCost()[2]) {
            dispatch(cartActions.removeDelivery(deliveryP._id))
        }

        const filtered = filteredRetailPoints.filter((rp) => {
            if (city) {
                return rp.addressFull.city === city.data.city
            }
        })

        setFilteredRetailPoints(filtered)

        if (deliveryType?._id === '639c14a2aba8fec3262010d3') {
            if (filtered[0]) setRetailPoint(filtered[0]._id)
        }

        calcCart()

        saveAddress(null)
    }, [deliveryType])

    useEffect(() => {
        let rps: Array<IRetailPoint> = []
        Promise.resolve(retailPoints).then((retailPoints) => {
            retailPoints.map((rp) => {
                Promise.resolve(rp).then((r) => {
                    rps.push(r)
                })
            })
        })
        setRetailPointsSync(rps)
        setFilteredRetailPoints(rps)
    }, [retailPoints])

    useEffect(() => {
        Promise.resolve(settings).then((s) => {
            s.map((rp) => {
                Promise.resolve(rp).then((r) => {
                    setMinDeliveryCost(parseFloat(r.value))
                })
            })
        })
    }, [settings])

    useEffect(() => {
        Promise.resolve(deliveryProduct).then((s) => {
            Promise.resolve(s).then((r) => {
                setDeliveryP(r.values[0])
            })
        })
    }, [deliveryProduct])

    useEffect(() => {
        let dts: Array<IDeliveryType> = []
        Promise.resolve(deliveryTypes).then((deliveryTypes) => {
            deliveryTypes.map((dt) => {
                Promise.resolve(dt).then((d) => {
                    dts.push(d)
                })
            })
        })

        setDeliveryTypesSync(dts)
    }, [deliveryTypes])

    useEffect(() => {
        if (cityInput.length > 2) {
            searchAddress
                .get(cityInput, false, undefined)
                .then((data) => {
                    setCities(data.suggestions)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [cityInput])

    useEffect(() => {
        if (city) {
            let rps: Array<IRetailPoint> = []
            Promise.resolve(retailPoints).then((retailPoints) => {
                retailPoints.map((rp) => {
                    Promise.resolve(rp).then((r) => {
                        rps.push(r)
                    })
                })
            })

            const filtered = rps.filter((rp) => rp.addressFull.city === city.data.city)

            setFilteredRetailPoints(filtered)
            actualDeliveryTypes(city).then()
            setDeliveryType(deliveryTypesSync[0])

            setApartment('')
            setStreetInput('')
            setStreet(null)
        }
    }, [city])

    useEffect(() => {
        if (streetInput.length > 2) {
            searchAddress
                .get(streetInput, true, city?.data.city)
                .then((data) => {
                    let suggestions = data.suggestions.filter((d) => {
                        return !d.value.includes(', кв ')
                    })
                    setStreets(suggestions)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [streetInput])

    const onPay = (e: React.MouseEvent) => {
        e.preventDefault()

        const btn = e.currentTarget as HTMLButtonElement
        btn.disabled = true
        btn.classList.toggle('btnLoading')

        if (!city) {
            alert('Необходимо указать город')
            btn.disabled = false
            btn.classList.toggle('btnLoading')
            return
        }

        let address: IAddress
        if (deliveryType?._id === '639c14a2aba8fec3262010d3') {
            address = {
                deliveryTypeId: deliveryType._id || '',
                retailPointId: retailPoint,
            }
        } else {
            if (!street) {
                alert('Необходимо указать адрес доставки')
                btn.disabled = false
                btn.classList.toggle('btnLoading')
                return
            }
            if (house === '') {
                alert('Необходимо указать номер дома')
                btn.disabled = false
                btn.classList.toggle('btnLoading')
                return
            }
            if (apartment === '') {
                alert('Необходимо указать номер квартиры')
                btn.disabled = false
                btn.classList.toggle('btnLoading')
                return
            }
            address = {
                deliveryTypeId: deliveryType?._id || '',
                city: city.data.city_with_type,
                street: street.data.street_with_type,
                house,
                apartment,
                apartmentType: 'кв',
                comment,
            }
        }

        const payload: IOrder = {
            address,
            bonusOut: withdrawBonuses.toString(),
            check: true,
            comment: '',
            promocode,
            sourceId: '639c1b3a1ebccb09c5beb660',
        }

        const tmrObj = {
            type: 'reachGoal',
            id: 3288302,
            value:
                deliveryType && deliveryType.price
                    ? deliveryType.price +
                      getTotalDefaultPrice(cart) -
                      getSalesPrice(cart) -
                      withdrawBonuses -
                      promocodePrice
                    : getTotalDefaultPrice(cart) -
                      getSalesPrice(cart) -
                      withdrawBonuses -
                      promocodePrice,
            goal: 'purchase',
            params: {
                product_id: cart.map((c) =>
                    c.modifiers ? c.modifiers.article : c.product.article
                ),
            },
        }

        const eCommerce = {
            ecommerce: {
                currencyCode: 'RUB',
                purchase: {
                    actionField: {
                        id: Date.now().toString(36) + Math.random().toString(36).substring(2),
                        coupon: promocode && promocode !== '' ? promocode : undefined,
                        goal_id: 'MAKE_ORDER',
                    },
                    products: cart.map((c) => {
                        const obj = c.modifiers ? c.modifiers : c.product
                        const name =
                            /*c.modifiers ? `${c.product.name} ${c.modifiers.name}мл` :*/ c.product
                                .name
                        return {
                            id: obj.article,
                            name: name,
                            price: obj.priceDefault,
                            quantity: c.amount,
                        }
                    }),
                },
            },
        }

        orderService
            .processSale(payload)
            .then(() => {
                payload.check = false
                orderService
                    .create(payload)
                    .then(() => {
                        window._tmr.push(tmrObj)
                        window.dataLayer.push(eCommerce)
                        setTimeout(() => {
                            orderService
                                .confirm()
                                .then((data) => {
                                    btn.classList.toggle('btnLoading')
                                    console.log(data)
                                    /*[snippet: tinkoff]*/
                                    const TPF = TinkoffPayForm(data)
                                    // from tinkoff hook
                                    pay(TPF)
                                    /*[/snippet: tinkoff]*/
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                            btn.disabled = false
                            btn.classList.toggle('btnLoading')
                        }, 1000)
                    })
                    .catch((err) => {
                        console.log(err)
                        btn.disabled = false
                        btn.classList.toggle('btnLoading')
                    })
            })
            .catch((err) => {
                console.log(err)
                btn.disabled = false
                btn.classList.toggle('btnLoading')
            })
    }

    const TinkoffPayForm = (o: IConfirmOrderResponse): HTMLFormElement => {
        const TPF = document.createElement('form')
        TPF.name = 'payform-tinkoff'
        TPF.id = 'payform-tinkoff'

        let amount = 0
        if (kilbilTotal) amount = kilbilTotal

        TPF.appendChild(TinkoffPayFormInputElement('terminalkey', o.terminalId))
        TPF.appendChild(TinkoffPayFormInputElement('frame', 'false'))
        TPF.appendChild(TinkoffPayFormInputElement('language', 'ru'))
        TPF.appendChild(TinkoffPayFormInputElement('receipt', JSON.stringify(o.receipt)))
        TPF.appendChild(TinkoffPayFormInputElement('amount', o.amount.toString()))
        TPF.appendChild(TinkoffPayFormInputElement('order', o.orderNumber))
        TPF.appendChild(TinkoffPayFormInputElement('description', o.description))
        TPF.appendChild(TinkoffPayFormInputElement('name', o.customer.name))
        TPF.appendChild(TinkoffPayFormInputElement('email', o.customer.email))
        TPF.appendChild(TinkoffPayFormInputElement('phone', o.customer.phone))

        return TPF
    }

    const TinkoffPayFormInputElement = (name: string, value: string): HTMLInputElement => {
        const input = document.createElement('input')
        input.name = name
        input.value = value
        input.type = 'hidden'
        return input
    }

    const onChangeCity = (e: React.MouseEvent) => {
        e.preventDefault()
        if (cityBlockRef && cityBlockRef.current) {
            cityBlockRef.current.classList.add('d-none')
        }

        if (cityInputRef && cityInputRef.current) {
            cityInputRef.current.classList.remove('d-none')
        }
    }

    const onPickCity = (e: React.MouseEvent, c: ISearchAddressValue) => {
        e.preventDefault()
        setCity(c)

        if (cityBlockRef && cityBlockRef.current) {
            cityBlockRef.current.classList.remove('d-none')
        }

        if (cityInputRef && cityInputRef.current) {
            cityInputRef.current.classList.add('d-none')
        }
    }

    const onChangeStreet = (e: React.MouseEvent) => {
        e.preventDefault()

        if (streetBlockRef && streetBlockRef.current) {
            streetBlockRef.current.classList.add('d-none')
        }

        if (streetInputRef && streetInputRef.current) {
            streetInputRef.current.classList.remove('d-none')
        }
    }

    const onPickStreet = (e: React.MouseEvent, c: ISearchAddressValue) => {
        e.preventDefault()
        setStreetInput(() => c.value)
        setStreet(c)
        setTimeout(() => setStreets([]), 200)
    }

    const saveAddress = (e: React.MouseEvent | null) => {
        if (e !== null) e.preventDefault()

        if (streetBlockRef && streetBlockRef.current) {
            streetBlockRef.current.classList.remove('d-none')
        }

        if (streetInputRef && streetInputRef.current) {
            streetInputRef.current.classList.add('d-none')
        }
    }

    const actualDeliveryTypes = async (c: ISearchAddressValue) => {
        if (c) {
            let rps = retailPointsSync.filter(
                ({ addressFull }) => addressFull.city.toLowerCase() === c.data.city.toLowerCase()
            )
            setFilteredRetailPoints(rps)

            if (rps.length > 0) {
                setFilteredDeliveryTypes(deliveryTypesSync)
            } else {
                setFilteredDeliveryTypes([deliveryTypesSync[0]])
            }
        }
    }

    const deliveryCost = (): [string, number, IItemModifier | undefined] => {
        if (deliveryType && deliveryP) {
            const modifier = deliveryP.modifiers.find((m) => deliveryType.article === m.article)
            if (kilbilTotalDiscounted && modifier) {
                if (kilbilTotalDiscounted < minDeliveryCost || deliveryType.minOrderSum < 0)
                    return [priceWithSpace(modifier.priceDefault), modifier.priceDefault, modifier]
            }
        }
        return [priceWithSpace(0), 0, undefined]
    }

    const calcCart = () => {
        setLoading(true)
        const order: IOrder = {
            sourceId: '639c1b3a1ebccb09c5beb660',
            promocode,
            check: true,
            bonusOut: withdrawBonuses.toString(),
        }

        setTimeout(() => {
            orderService
                .processSale(order)
                .then(({ _bill_data: { discounted_total, total } }) => {
                    setKilbilTotal(total)
                    setKilbilTotalDiscounted(discounted_total)
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }, 1000)
    }

    return (
        <Page hideMiniCart>
            <Helmet>
                <title>Офомление заказа</title>
                <link rel='canonical' href='https://parfumeratelier.ru/order' />
                <meta property='og:title' content={`Офомление заказа`} />
                <meta property='og:url' content={`https://parfumeratelier.ru/order`} />
            </Helmet>
            <div className={classes.order}>
                <div className={classes.body}>
                    <div className={clsx(classes.step)}>
                        <div className={classes.header}>
                            <span>адрес и доставка</span>
                        </div>

                        <div className={classes.row}>
                            <p>населённый пункт</p>
                            <div ref={cityBlockRef} className={classes.place}>
                                {city ? (
                                    <>
                                        <span>{city.value}</span>
                                        <span>{city.data.region_with_type}</span>
                                    </>
                                ) : (
                                    <span>Выберите город</span>
                                )}
                                <button className='btn--sup' onClick={onChangeCity}>
                                    изменить город
                                </button>
                            </div>
                            <div className={clsx(classes.place, 'd-none')} ref={cityInputRef}>
                                <div className='form__input'>
                                    <label>Город</label>
                                    <input
                                        type='text'
                                        name='city'
                                        value={cityInput}
                                        onChange={({ target: { value } }) => setCityInput(value)}
                                    />
                                </div>
                                <ul className={classes.suggest}>
                                    {cities.map((c, i) => (
                                        <li key={`city_${i}`} onClick={(e) => onPickCity(e, c)}>
                                            {c.value}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.row, { ['d-none']: !city })}>
                            <p>способ доставки</p>
                            <div className={classes.place}>
                                <div className='select-box'>
                                    <div className='select-box__current' tabIndex={1}>
                                        {filteredDeliveryTypes.map((dt) => (
                                            <div key={dt._id} className='select-box__value'>
                                                <input
                                                    className='select-box__input'
                                                    type='radio'
                                                    id={dt._id}
                                                    value={dt._id}
                                                    onChange={() => setDeliveryType(dt)}
                                                    name='deliveryType'
                                                    checked={dt._id === deliveryType?._id}
                                                />
                                                <p className='select-box__input-text'>{dt.name}</p>
                                            </div>
                                        ))}
                                        <img
                                            className='select-box__icon'
                                            src={Arrow}
                                            alt='Arrow Icon'
                                            aria-hidden='true'
                                        />
                                    </div>
                                    <ul className='select-box__list'>
                                        {filteredDeliveryTypes.map((dt) => (
                                            <li key={`li_${dt._id}`}>
                                                <label
                                                    className='select-box__option'
                                                    htmlFor={dt._id}
                                                    aria-hidden='true'>
                                                    {dt.name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {deliveryType && deliveryType.price > 0 ? (
                                    <p className={classes.deliveryNotice}>
                                        Стоимость доставки {deliveryType.price}₽
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        <div className={clsx(classes.row, { ['d-none']: !city })}>
                            {deliveryType && deliveryType._id === '639c14a2aba8fec3262010d3' ? (
                                <>
                                    <p>точка самовывоза</p>
                                    <div className={classes.place} ref={streetBlockRef}>
                                        <div className='select-box'>
                                            <div className='select-box__current' tabIndex={1}>
                                                {filteredRetailPoints.map((rp) => (
                                                    <div key={rp._id} className='select-box__value'>
                                                        <input
                                                            className='select-box__input'
                                                            type='radio'
                                                            id={rp._id}
                                                            value={rp._id}
                                                            onChange={() => setRetailPoint(rp._id)}
                                                            name='retailPoint'
                                                            checked={rp._id === retailPoint}
                                                        />
                                                        <p className='select-box__input-text'>
                                                            {rp.name}
                                                        </p>
                                                    </div>
                                                ))}
                                                <img
                                                    className='select-box__icon'
                                                    src={Arrow}
                                                    alt='Arrow Icon'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <ul className='select-box__list'>
                                                {filteredRetailPoints.map((rp) => (
                                                    <li key={`li_${rp._id}`}>
                                                        <label
                                                            className='select-box__option'
                                                            htmlFor={rp._id}
                                                            aria-hidden='true'>
                                                            {rp.name}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p>адрес доставки</p>
                                    <div className={classes.place} ref={streetBlockRef}>
                                        {street ? (
                                            <span>
                                                {street.data.street_with_type}, д. {house}, кв.{' '}
                                                {apartment}
                                            </span>
                                        ) : (
                                            <span>Выберите улицу</span>
                                        )}
                                        <button className='btn--sup' onClick={onChangeStreet}>
                                            изменить адрес
                                        </button>
                                    </div>
                                    <div
                                        className={clsx(classes.place, 'd-none')}
                                        ref={streetInputRef}>
                                        <div className='form__input'>
                                            <label>Улица</label>
                                            <input
                                                ref={streetRef}
                                                type='text'
                                                name='street'
                                                value={streetInput}
                                                onChange={({ target: { value } }) =>
                                                    setStreetInput(value)
                                                }
                                            />
                                        </div>
                                        <ul className={classes.suggest}>
                                            {streets.map((c, i) => (
                                                <li
                                                    key={`city_${i}`}
                                                    onClick={(e) => onPickStreet(e, c)}>
                                                    {c.value}
                                                </li>
                                            ))}
                                        </ul>
                                        <div className={classes.addressRow}>
                                            <div className='form__input'>
                                                <label>Дом</label>
                                                <input
                                                    type='text'
                                                    value={house}
                                                    name='home'
                                                    onChange={({ target: { value } }) =>
                                                        setHouse(value)
                                                    }
                                                />
                                            </div>
                                            <div className='form__input'>
                                                <label>Квартира</label>
                                                <input
                                                    type='text'
                                                    value={apartment}
                                                    name='flat'
                                                    onChange={({ target: { value } }) =>
                                                        setApartment(value)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <button className='btn--sup' onClick={saveAddress}>
                                            Сохранить адрес
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={classes.row}>
                            <p>Комментарий</p>
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className={classes.comment}></textarea>
                        </div>
                    </div>

                    <div className={classes.step}>
                        <div className={classes.row}>
                            <span />
                            <button onClick={onPay} className='btn'>
                                <span>Оплатить online</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={classes.detail}>
                    <div className='lds-ring' ref={loaderRef}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <h2>ваш заказ</h2>
                    <div className={classes.detailProducts}>
                        {cart.map((c) =>
                            c.product.slug !== 'dostavka' ? (
                                <img
                                    key={c._id}
                                    src={c.product.imgUrl[0] || Placeholder}
                                    alt={c.product.name}
                                />
                            ) : null
                        )}
                    </div>
                    <div className={classes.attrs}>
                        <dl className={classes.tags}>
                            <div className={classes.tag}>
                                <dt className={classes.tagKey}>
                                    <span>сумма заказа</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{priceWithSpace(getTotalDefaultPrice(cart))}</span>
                                </dd>
                            </div>
                            <div className={classes.tag}>
                                <dt className={classes.tagKey}>
                                    <span>доставка</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{deliveryCost()[0]}</span>
                                </dd>
                            </div>
                            <div className={clsx(classes.tag, classes.discount)}>
                                <dt className={classes.tagKey}>
                                    <span>скидка</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>
                                        {kilbilTotal &&
                                            kilbilTotalDiscounted &&
                                            priceWithSpace(kilbilTotal - kilbilTotalDiscounted)}
                                    </span>
                                </dd>
                            </div>
                            {promocode && promocodePrice > 0 ? (
                                <div className={clsx(classes.tag, classes.discount)}>
                                    <dt className={classes.tagKey}>
                                        <span>Промокод {promocode}</span>
                                    </dt>
                                    <dd className={classes.tagValue}>
                                        <span>{-promocodePrice}</span>
                                    </dd>
                                </div>
                            ) : null}
                            <div className={clsx(classes.tag, classes.discount)}>
                                <dt className={classes.tagKey}>
                                    <span>бонусы</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{priceWithSpace(-withdrawBonuses)}</span>
                                </dd>
                            </div>
                            <div className={clsx(classes.tagTotal)}>
                                <span>Итого</span>
                                <span>
                                    {kilbilTotalDiscounted && priceWithSpace(kilbilTotalDiscounted)}
                                    ₽
                                </span>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Order
