import React, { useEffect } from 'react'
import Page from '../Page/Page'
import classes from './Documents.module.css'
import { polyfill, scrollIntoView, scrollTo, elementScrollIntoView } from 'seamless-scroll-polyfill'
import { useSearchParams } from 'react-router-dom'

const Documents = () => {
    polyfill()
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        const query = window.location.search.split('=')[1]
        if (query) {
            if (query === 'delivery') window.scrollTo(0, 0)
            else {
                const block = document.querySelector(`span[data-block="${query}"]`)
                block?.scrollIntoView(true)
            }
            // if (block) {
            //     scrollIntoView(block, { behavior: 'smooth' })
            // }
            // window.scrollBy(0, -100)
        }
    }, [])

    useEffect(() => {
        const query = searchParams.get('block')
        if (query) {
            if (query === 'delivery') window.scrollTo(0, 0)
            else {
                const block = document.querySelector(`span[data-block="${query}"]`)
                block?.scrollIntoView(true)
            }

            // if (block) {
            //     elementScrollIntoView(block, { behavior: 'smooth' })
            // }
        }
    }, [searchParams])

    const onClick = (e: React.MouseEvent, name: string) => {
        // e.preventDefault()
        // const block = document.querySelector(`span[data-block="${name}"]`)
        // console.log(block)

        // if (block) {
        //     scrollIntoView(block, { behavior: 'smooth' })
        // }
        setSearchParams({ block: name })
        // window.scrollBy(0, -100)
    }

    return (
        <Page>
            <div className={classes.documents}>
                <div className={classes.header}>
                    <div className={classes.empty} />
                    <h2>Документы сайта</h2>
                    <div />
                </div>

                <div className={classes.content}>
                    <nav className={classes.nav}>
                        <h3 onClick={(e) => onClick(e, 'delivery')}>Доставка &#8594;</h3>
                        <h3 onClick={(e) => onClick(e, 'reqs')}>Реквизиты &#8594;</h3>
                        <h3 onClick={(e) => onClick(e, 'offer')}>Договор оферты &#8594;</h3>
                        <h3 onClick={(e) => onClick(e, 'policy')}>
                            Конфиденциальность и защита информации &#8594;
                        </h3>
                        <h3 onClick={(e) => onClick(e, 'return')}>
                            Правила возврата товара &#8594;
                        </h3>
                        <h3 onClick={(e) => onClick(e, 'loyalty')}>
                            О программе лояльности &#8594;
                        </h3>
                        {/*<h3 onClick={e => onClick(e,"certs")}>Сертификаты &#8594;</h3>*/}
                    </nav>
                    <div className={classes.text}>
                        <span data-block='delivery' />
                        <h2>Доставка</h2>
                        <ul>
                            <li>
                                Самовывоз - бесплатно ( возможен только для городов, где есть
                                магазины Parfumer Atelier);
                            </li>
                            <li>
                                Доставка почта/сдэк осуществляется до пункта выдачи, согласно
                                ближайшему адресу, указанному при заказе, если сумма заказа больше
                                3000 рублей - доставка бесплатно.
                                <br />
                                Если сумма заказа менее 3000 рублей, то стоимость доставки 300
                                рублей;
                            </li>
                            <li>
                                Доставка курьером (срочная) для заказов оформленных до 17.00 - 450
                                рублей, в течении дня - 300 рублей.
                                <br />
                                Доставка курьером доступна только для городов, где есть магазины
                                Parfumer Atelier.
                            </li>
                        </ul>
                        <span
                            style={{ display: 'block', width: 10, height: 10, marginBottom: '8em' }}
                            data-block='reqs'
                        />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                        <h2>Реквизиты</h2>
                        <p>
                            ИП Бельтиков Роман Евгеньевич
                            <br />
                            ИНН: 420217196063
                            <br />
                            ОГРНИП: 321420500082730
                            <br />
                            Номер счёта: 40802810400003886769
                            <br />
                            Банк: АО "ТБанк"
                            <br />
                            {/* БИК: 045004774
                            <br /> */}
                            Адрес: 366022, РОССИЯ, Чеченская Респ, г Аргун, село Чечен-Аул, ул
                            им.Чими Дуки, д 24, кв 2
                        </p>
                        <span
                            style={{ display: 'block', width: 10, height: 10, marginBottom: '8em' }}
                            data-block='offer'
                        />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                        <h2>Договор оферты</h2>
                        <h3>Основные понятия</h3>
                        <p>
                            Пользователь — физическое лицо, посетитель Сайта, принимающий условия
                            настоящего Соглашения и желающий разместить Заказы на сайте
                            https://parfumeratelier.ru
                        </p>
                        <p>
                            Покупатель — Пользователь, разместивший Заказ на сайте
                            https://parfumeratelier.ru
                        </p>
                        <p>
                            Интернет-магазин — Интернет-сайт https://parfumeratelier.ru,
                            принадлежащий Продавцу, расположенный в сети интернет по адресу
                            https://parfumeratelier.ru, где представлены Товары, предлагаемые
                            Продавцом для приобретения, а также условия оплаты и доставки Товаров
                            Покупателям.
                        </p>
                        <p>Сайт — https://parfumeratelier.ru</p>
                        <p>Товар — сертификаты на селективную парфюмерию, селективная парфюмерия</p>
                        <p>
                            Заказ — должным образом оформленный запрос Покупателя на приобретение и
                            доставку по указанному Покупателем адресу и Товаров, выбранных на Сайте.
                        </p>
                        <h3>1. Общие положения</h3>
                        <div>
                            1.1. Продавец осуществляет продажу Товаров через интернет-магазин,
                            расположенный по адресу: https://parfumeratelier.ru
                            <br />
                            1.2. Предложенные на Сайте условия продажи Товаров, включая всю
                            информацию о Товаре, способах и сроках его доставки, оплаты и получения,
                            размещенные на Сайте, представляют из себя Договор Оферты.
                            <br />
                            1.3. Осуществляя Заказ Товара через интернет-магазин, Пользователь
                            принимает условия Договора Оферты и условия продажи Товара, отраженные в
                            разделах 2-11 Договора Оферты. Отказ от принятия условий Договора Оферты
                            и условий продажи Товара возлагает на Пользователя обязанность перестать
                            использовать Сайт.
                            <br />
                            1.4. Договор Оферты в целом или в части может быть изменен Продавцом в
                            одностороннем порядке, уведомление Пользователя/Покупателя о
                            произведенных изменениях не требуется. Принимая условия настоящего
                            Договора Оферты, Пользователь/Покупатель соглашается, что внесенные
                            Продавцом изменения вступают в силу в течение 1 (Одного) календарного
                            дня с момента совершения публикации новой редакции Договора Оферты на
                            Сайте.
                            <br />
                            1.5. Оферта признается акцептованной (принятой)
                            Пользователем/Покупателем с момента выполнения одного из следующих
                            условий:
                            <ul>
                                <li>
                                    оформления Заказа посредством общения с менеджерами
                                    интернет-магазина по номерам телефонов, указанным на Сайте;
                                </li>
                                <li>
                                    осуществление Заказа посредством адреса электронной почты
                                    интернет-магазина
                                </li>
                                <li>
                                    регистрации на Сайте или добавлении необходимого Товара в
                                    корзину Покупателя;
                                </li>
                            </ul>
                        </div>
                        <h3>2. Предмет соглашения</h3>
                        <p>
                            2.1. Настоящий Договор Оферты регулирует взаимоотношения по оказанию
                            услуг Пользователю Сайта/Покупателю, связанные с заказом и приобретением
                            размещенного на Сайте ассортимента Товаров. Договор Оферты
                            распространяется на взаимоотношения Продавца и Пользователя/Покупателя
                            по вопросам ознакомления с информацией о Товаре, размещенном для продажи
                            на Сайте, заказа Товара, а также приобретения Товара с целью потребления
                            для личных и иных, тесно связанных с ними нужд, не в целях
                            предпринимательства. Данный Договор Оферты не является предложением о
                            заключении договора поставки Товара за пределы г. Москвы.
                            <br />
                            2.2. Действие Договора Оферты распространяется на весь ассортимент
                            Товаров и услуг, размещенных на Сайте.
                        </p>
                        <h3>3. Оформление заказа, регистрация на сайте</h3>
                        <div>
                            3.1. Заказ Товара Пользователем осуществляется посредством телефонного
                            общения с менеджерами интернет-магазина, а также через сервисы
                            электронной почты ru или путем добавления товара в корзину на Сайте.
                            <br />
                            33.3. Для оформления Заказа или обеспечения возможности правильного
                            выбора Товара, уточнения способов оплаты и доставки Товара, ответов на
                            иные возможные вопросы (комментарии, предложения, отзывы и пр.)
                            Пользователю необходимо предоставить информацию о своем имени, номере
                            контактного телефона для связи и адресе электронной почты. Сообщая
                            вышеуказанную информацию, Пользователь/Покупатель дает свое согласие на
                            использование данной информации в следующих целях:
                            <ul>
                                <li>
                                    для осуществления связи с Пользователем/Покупателем в целях
                                    исполнения предмета настоящего договора Оферты;
                                </li>

                                <li>
                                    в целях распространения информации о торговых акциях
                                    интернет-магазина https://parfumeratelier.ru
                                </li>

                                <li>
                                    иных целях, указанных в Положении о защите персональных данных и
                                    конфиденциальности информации.
                                </li>
                            </ul>
                            3.4. Пользователь/Покупатель полностью отвечает за достоверность
                            информации, предоставленной Продавцу.
                        </div>
                        <h3>4. Порядок совершения покупки</h3>
                        <div>
                            4.1. Информация о Товарах (об Изготовителе, технических характеристиках,
                            основных потребительских свойствах, сроке годности, правилах безопасного
                            использования Товара, о месте изготовления Товара, о полном фирменном
                            наименовании (наименовании) изготовителя, о цене и об условиях
                            приобретения Товара, о его доставке, о порядке оплаты Товара и пр. ),
                            обеспечивающая возможность их правильного выбора, размещена на Сайте и
                            может быть указана в документации, прилагаемой к Товарам, на этикетках,
                            маркировкой или иным способом, а также может быть предоставлена устно,
                            письменно или лично, по требованию Пользователя Сайта/Покупателя.
                            <br />
                            4.2. Руководствуясь п. 1.5 настоящего Договора Оферты, приобретение
                            Товара Пользователем Сайта осуществляется, в том числе посредством
                            телефонного общения. Стороны Договора Оферты приходят к соглашению, что
                            взаимное общение Пользователя/Покупателя и менеджеров интернет-магазина
                            должно строиться на принципах уважения чести, достоинства и деловой
                            репутации. Стороны Договора Оферты предупреждены о гражданской,
                            административной и уголовной ответственности за причинение оскорблений,
                            угроз и прочих форм неэтичного поведения, способных попасть под
                            классификацию правонарушения/преступления.
                            <br />
                            4.3. Принимая условия настоящего Договора Оферты, Пользователь
                            Сайта/Покупатель соглашается, что порядок обработки Заказа
                            интернет-магазином и исполнение Продавцом обязательств по приобретению
                            Товара Пользователем/Покупателем может состоять из следующих этапов:
                            <ul>
                                <li>
                                    ознакомление Гостя Сайта/Пользователя/Покупателя с информацией,
                                    размещенной на Сайте, пребывание на сайте;
                                </li>
                                <li>
                                    консультирование Пользователя/Покупателя сотрудниками
                                    интернет-магазина по вопросам оформления Заказа и приобретения
                                    Товара, способах его оплаты и доставки;
                                </li>
                                <li>
                                    получение согласия Пользователя/Покупателя на приобретение
                                    заказанного им Товара (согласие Пользователя/Покупателя может
                                    быть выражено тем же способом, каким сделан Заказ Товара),
                                    принятие интернет-магазином Заявки;
                                </li>
                                <li>
                                    после оформления Заказа менеджер интернет-магазина,
                                    обслуживающий данный Заказ, вправе уточнить детали Заказа,
                                    согласовывать дату доставки или интервал предполагаемых дат
                                    доставки. Сроки доставки Товара зависят от типа доставки,
                                    выбранного Пользователем/Покупателем и Региона доставки.
                                    уведомление Пользователя/Покупателя о предполагаемой дате
                                    (интервале дат) доставки Товара осуществляется посредством
                                    телефонной связи или путем направления электронного сообщения по
                                    адресу, указанному Пользователем/Покупателем. При осуществлении
                                    связи с Пользователем/Покупателем со стороны менеджера
                                    интернет-магазина устанавливается ограничение по количеству
                                    телефонных звонков/sms в отношении каждого Заказа Товара
                                    Пользователем/Покупателем в количестве 10(десять) повторений или
                                    не более 3 (трех) календарных дней подряд в случае, если связь с
                                    Пользователем/Покупателем отсутствует;
                                </li>
                                <li>
                                    осуществление процедуры приема-передачи Товара Пользователю
                                    Сайта/Покупателю в случае самовывоза или контроль за
                                    приемом-передачей заказанного Товара в Регион;
                                </li>
                                <li>урегулирование разногласий.</li>
                            </ul>
                            4.4. Способы оплаты Товара, заказанного на Сайте, регламентированы
                            Разделом 6 настоящего Договора Оферты.
                            <br />
                            4.5. Способы доставки Товара, заказанного на Сайте, регламентированы
                            Разделом 5 настоящего договора публичной оферты.
                        </div>
                        <h3>5. Оплата товара</h3>
                        <div>
                            6.1. Оплата Товара осуществляется путем использования национальных
                            платежных инструментов, а также наличных расчетов. Договором Оферты
                            устанавливаются следующие способы оплаты Товара Покупателем:
                            <ul>
                                <li>оплата Товара наличными денежными средствами при получении;</li>
                                <li>оплата банковским переводом на расчетный счет Продавца;</li>
                                <li>оплата Товара банковской картой на Сайте;</li>
                                <li>
                                    оплата товара с помощью электронных сервисов «Яндекс. Деньги»;
                                </li>
                            </ul>
                            Перечень доступных способов оплаты может быть расширен Продавцом в
                            одностороннем порядке без предварительного уведомления.
                            <br />
                            6.2. В соответствии с гражданским законодательством РФ, валютой
                            совершения денежных операций является российский рубль.
                            <br />
                            6.3. Цена Товара указывается Продавцом на Сайте.
                            <br />
                            6.4. Особенности оплаты Товара с помощью банковских карт:
                            <ul>
                                <li>
                                    в соответствии с положением Центрального Банка РФ от
                                    24.12.2004г. № 266-П «Об эмиссии банковских карт и об операциях,
                                    совершаемых с использованием платежных карт» операции по
                                    банковским картам совершаются держателем карты либо
                                    уполномоченным им лицом, Пользователь/Покупатель гарантирует,
                                    что является законным владельцем используемой им для оплаты
                                    банковской карты и действует добросовестно;
                                </li>
                                <li>
                                    авторизация операций по банковским картам осуществляется банком.
                                    Если у банка есть основания полагать, что операция носит
                                    мошеннический характер, то банк вправе отказать в осуществлении
                                    данной операции;
                                </li>
                                <li>
                                    в случаях, установленных законодательством, все Заказы,
                                    оформленные на Сайте и предоплаченные банковской картой, во
                                    избежание различного рода неправомерного использования
                                    банковских карт при оплате, могут быть проверены Продавцом в
                                    целях проверки личности владельца и его правомочности на
                                    использование карты. Продавец вправе потребовать от Покупателя,
                                    оформившего такой Заказ, предъявления документа, удостоверяющего
                                    личность.
                                </li>
                            </ul>
                        </div>
                        <span
                            style={{ display: 'block', width: 10, height: 10, marginBottom: '8em' }}
                            data-block='policy'
                        />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                        <h2>Конфиденциальность и защита информации</h2>
                        <div>
                            1.1. Настоящий раздел регулируется следующими нормативно-правовыми
                            актами: Федеральным законом РФ 27.07.2006г. № 152-ФЗ «О персональных
                            данных», Федеральным законом РФ от 27.07.2006г. № 149-ФЗ «Об информации,
                            информационных технологиях и о защите информации».
                            <br />
                            1.2. С целью реализации прав по Договору Оферты Гость
                            Сайта/Пользователь/Покупатель предоставляет о себе следующую информацию:
                            <ul>
                                <li>фамилия (обязательно);</li>
                                <li>имя (обязательно);</li>
                                <li>номер телефона (обязательно);</li>
                                <li>адрес электронной почты (обязательно);</li>
                                <li>
                                    пароль для доступа к личному кабинету (обязателен для
                                    осуществления регистрации на Сайте);
                                </li>
                                <li>дату рождения (необязательно).</li>
                            </ul>
                            1.3. Под обработкой персональных данных понимается любое действие
                            (операция) или совокупность действий (операций), совершаемых с
                            использованием средств автоматизации или без использования таких средств
                            с персональными данными, включая сбор, запись, систематизацию,
                            накопление, хранение, уточнение (обновление, изменение), извлечение,
                            использование, передачу (в том числе передачу третьим лицам, не исключая
                            трансграничную передачу, если необходимость в ней возникла в ходе
                            исполнения обязательств), обезличивание, блокирование, удаление,
                            уничтожение персональных данных.
                            <br />
                            1.4. В соответствии с пп. 2 п. 2 ст. 22 Федерального закона РФ от
                            27.07.2006г. № 152-ФЗ «О персональных данных» Гость
                            Сайта/Пользователь/Покупатель, принимая условия настоящего Договора
                            Оферты, выступая в качестве субъекта персональных данных, дает свое
                            согласие на обработку своих персональных данных Продавцом, а также
                            Продавцом в отношениях с третьими лицами, а Продавец обязуется
                            обрабатывать такие данные вышеуказанными способами исключительно для
                            целей исполнения предмета настоящего договора и целей, указанных в
                            «Положении о защите персональных данных и конфиденциальности
                            информации», являющемся неотъемлемой частью настоящего Договора Оферты.
                            <br />
                            1.5. Продавец не несет ответственности за сведения, предоставленные
                            Гостем Сайта/Пользователем/Покупателем на Сайте в общедоступной форме.
                            <br />
                            1.6. Продавец вправе осуществлять записи телефонных разговоров с Гостем
                            Сайта/Пользователем/Покупателем. При этом Продавец обязуется
                            предотвращать попытки несанкционированного доступа к информации,
                            полученной в ходе телефонных переговоров и/или передачу ее третьим
                            лицам, не имеющим непосредственного отношения к исполнению Заказов, в
                            соответствии с п. 4 ст. 16 Федерального закона от 27.07.2006г. № 149-ФЗ
                            «Об информации, информационных технологиях и о защите информации».
                            <br />
                            1.7. Отзыв согласия на обработку персональных данных осуществляется
                            путем отзыва акцепта настоящего Договора Оферты.
                        </div>
                        <span
                            style={{ display: 'block', width: 10, height: 10, marginBottom: '8em' }}
                            data-block='return'
                        />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                        <h2>Правила возврата товара</h2>
                        <ul>
                            <li>
                                Согласно Постановлению Правительства №2463 от 31.12.2020 «Об
                                утверждении правил продажи товаров по договору розничной
                                купли-продажи, перечня товаров длительного пользования, на которые
                                не распространяется требование потребителя о безвозмездном
                                предоставлении ему товара, обладающего этими же основными
                                потребительскими свойствами, на период ремонт или замены такого
                                товара, и перечня непродовольственных товаров надлежащего качества,
                                не подлежащих обмену, а также о внесении изменений в некоторые акты
                                Правительства РФ, парфюмерно-косметические товары надлежащего
                                качества не подлежат возврату или обмену.
                            </li>
                            <li>
                                Если вы получили Товар ненадлежащего качества (товар в поврежденной,
                                смятой упаковке, с нарушенной слюдой, с подтеками, с видимыми
                                сколами или повреждениями, с осадком), то такой Товар подлежит
                                обмену или возврату с компенсацией оплаченной стоимости за
                                возвращаемый Товар. Для получения обмена или компенсации необходимо:
                                <ol>
                                    <li>
                                        Направить по электронному адресу{' '}
                                        <a href='mailto:parfumeratelier@mail.ru'>
                                            parfumeratelier@mail.ru
                                        </a>{' '}
                                        заявление на обмен или возврат некачественного товара.
                                    </li>
                                    <li>Бланк заявления можно скачать по ссылке.</li>
                                    <li>
                                        К заявлению приложить также фотографии упаковки со всеми
                                        этикетками и повреждениями (при наличии) и самого
                                        поврежденного/не соответствующего заявленному Товару
                                    </li>
                                    <li>
                                        В заявлении также необходимо указать номер заказа,
                                        контактные данные Клиента/Потребителя и подробное описание
                                        причины возврата.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Рассмотрение претензии осуществляется в течение 10 дней с момента ее
                                получения. Для принятия решения о возврате Товара Вам будет
                                необходимо предъявить сам Товар, направив его по адресу,
                                направленному в ответном письме, и приложив сопроводительные
                                документы к Заказу (заявление на возврат).
                            </li>
                            <li>
                                Денежные средства, оплаченные за Товар, и почтовые расходы (только в
                                случае возврата Товара ненадлежащего качества) на возврат Товара
                                будут возвращены на Ваш банковский расчетный счет в течение 10 дней
                                с момента получения Заявления на возврат и возвращенного Товара. В
                                случае не подтверждения брака, расходы на возврат Товара надлежащего
                                качества несет Клиент и Продавцом не возмещаются.
                            </li>
                            <li>
                                При получении заказа курьерской доставкой и обнаружении Товара
                                ненадлежащего качества Вам необходимо оформить документ на возврат
                                (накладная), заполнив соответствующие поля в накладной с указанием
                                всех выявленных недостатков и вернуть Товар курьеру вместе с
                                заполненным и подписанным документом. Денежные средства за Товар,
                                если заказ был оплачен, будут возвращены в течение 10 рабочих дней
                                (банковских дней) с момента получения возвращенного Товара
                                Продавцом.
                            </li>
                            <li>
                                Денежные средства подлежат возврату следующими способами:
                                <ol>
                                    <li>
                                        при оплате Заказа с использованием банковской карты,
                                        денежные средства подлежат возврату только на счет
                                        банковской карты, с использованием которой была произведена
                                        оплата.
                                    </li>
                                    <li>
                                        при оплате Заказа наличными денежными средствами, денежные
                                        средства подлежат возврату на банковский счет, указанный
                                        Клиентом в заявлении на возврат денежных средств.
                                    </li>
                                </ol>
                            </li>
                        </ul>
                        <span
                            style={{ display: 'block', width: 10, height: 10, marginBottom: '8em' }}
                            data-block='loyalty'
                        />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                        <h2>О программе лояльности</h2>
                        <p>
                            Для участия в программе вам необходимо зарегистрироваться на сайте{' '}
                            <a href='https://bonus.parfum-atelier.com/'>
                                https://bonus.parfum-atelier.com
                            </a>
                            .
                        </p>
                        <p>При регистрации вы получаете 300 приветственных бонусов.</p>
                        <p>
                            За покупки в оффлайн или онлайн магазине Parfumer Atelier вам
                            начисляется кэшбэк в виде бонусов в размере:
                        </p>
                        <ul>
                            <li>3% - назначается сразу при регистрации в программе;</li>
                            <li>5% - при совершении покупок на общую сумму от 30000₽;</li>
                            <li>7% - при совершении покупок на общую сумму от 70000₽;</li>
                            <li>
                                10% + VIP статус - при совершении покупок на общую сумму от 100000₽.
                            </li>
                        </ul>
                        <p>
                            VIP статус предоставляет доступ к закрытым распродажам, предзаказу
                            новинок, бесплатной доставке и другим привилегиям. Об этом мы сообщим
                            вам в индивидуальной рассылке.
                        </p>
                        <p>1 бонус = 1 рубль.</p>
                        <p>Вы можете оплатить бонусами до 30% от суммы покупки.</p>
                        <p>
                            Новая программа лояльности действует с 09.12.2022. С этой даты, при
                            следующей покупке, вы автоматически перейдете на новую программу
                            лояльности, и вся информация о ваших бонусах отобразится на вашей карте.
                            Все накопленные вами до 09.12.2022 бонусы также сохраняются и
                            автоматически перенесутся на новую карту.
                        </p>
                        <span data-block='certs' />
                        {/*<br/><br/>*/}
                        {/*<h2>Сертификаты</h2>*/}
                        {/*<a href="https://drive.google.com/file/d/1REtbYyO1OozFqjkrMEUg2hEuiv7uvdfo/view" target="_blank" rel="noopener noreferrer nofollow">Сертификаты</a>*/}
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Documents
