import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import Page from '../Page/Page'
import cls from './index.module.css'
import F1 from '../../assets/images/franchise/f1.jpg'
import F2 from '../../assets/images/franchise/f2.JPG'
import F3 from '../../assets/images/franchise/f3.JPG'
import F4 from '../../assets/images/franchise/f4.JPG'
import F5 from '../../assets/images/franchise/f5.JPG'
import { Link } from 'react-router-dom'

export const Franchise = () => {
    const [isAgree, setAgree] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { elements } = e.target as HTMLFormElement
        const name = (elements.namedItem('name') as HTMLInputElement).value
        const phone = (elements.namedItem('phone') as HTMLInputElement).value
        const comment = (elements.namedItem('comment') as HTMLTextAreaElement).value
        setLoading(true)
        fetch('https://tg.clockwork.one/v1/notify', {
            headers: {
                'X-Access-Key': 'afc22027d4356718c095d45829298763d960b867',
            },
            method: 'POST',
            body: JSON.stringify({
                name: 'franchise',
                fields: [
                    {
                        key: 'name',
                        value: name,
                    },
                    {
                        key: 'phone',
                        value: phone,
                    },
                    {
                        key: 'comment',
                        value: comment,
                    },
                ],
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    alert('Произошла ошибка при отправке формы')
                } else {
                    ;(e.target as HTMLFormElement).reset()
                    ;(elements.namedItem('phone') as HTMLInputElement).value = ''
                    alert('Форма успешно отправлена')
                }
            })
            .catch((e) => {
                alert('Произошла ошибка при отправке формы')
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <Page transparentHeader={false}>
            <div className={cls.about}>
                <div className={cls.about_text}>
                    <h1>Франшиза Parfumer Atelier</h1>
                    <p>
                        Parfumer Atelier — франшиза парфюмерных магазинов. Большой опыт,
                        качественный продукт, проработанная концепция с доказанной эффективностью.
                        Партнёр получает пошаговые инструкции, помощь в подборе торговой площади,
                        сотрудников, их обучении.
                    </p>
                    <small>Возможно провести ребрендинг действующего магазина.</small>
                </div>
                <div>
                    <img src={F1} />
                </div>
            </div>
            <div className={cls.polosa}>
                <div className={cls.polosa_inner}>
                    <div className={cls.polosa_items}>
                        <div className={cls.polosa_item}>
                            <span>8</span>
                            <span>Собственных магазинов</span>
                        </div>
                        <div className={cls.polosa_item}>
                            <span>11</span>
                            <span>Магазинов по франшизе</span>
                        </div>
                        <div className={cls.polosa_item}>
                            <span>
                                5 <small>млн</small>
                            </span>
                            <span>Инвестиции</span>
                        </div>
                        <div className={cls.polosa_item}>
                            <span>
                                4 <small>%</small>
                            </span>
                            <span>Роялти*</span>
                        </div>
                    </div>
                    <small>*Паушальный зависит от территории действия франшизы</small>
                </div>
            </div>
            <p className={cls.text}>
                С каждым своим франчайзи мы работаем по специально разработанной схеме. При
                заключении партнерского соглашения закрепляем за вами наставника с момента
                заключения договора до официального открытия и в последующей работе.
            </p>
            <div className={cls.imgs}>
                <img src={F2} />
                <img src={F3} />
                <img src={F4} />
                <img src={F5} />
            </div>

            <div className={cls.feedback}>
                <div className={cls.fb_info}>
                    <h2>Что входит в паушальный взнос Parfumer Atelier:</h2>
                    <ul>
                        <li>• Помощь в поиске и подборе Торгового центра и помещения.</li>
                        <li>• Участие в переговорах с Торговым центром.</li>
                        <li>• Предоставление типовых дизайнерских решений.</li>
                        <li>• Помощь в создании дизайн-проекта.</li>
                        <li>• Помощь в подборе поставщиков.</li>
                        <li>• Помощь в подборе персонала.</li>
                        <li>• Стартовое обучение для сотрудников.</li>
                        <li>• Предоставление и контроль соблюдения стандартов работы.</li>
                        <li>• Сопровождение на всех этапах работы.</li>
                    </ul>
                </div>
                <div className={cls.fb_form_wrapper}>
                    <form className={cls.fb_form} onSubmit={onSubmit}>
                        <h2>Я хочу франшизу!</h2>
                        <div className={cls.inputs}>
                            <input placeholder='Имя' name='name' required />
                            {/* @ts-ignore */}
                            <InputMask
                                required
                                type='text'
                                mask='+7 (999) 999-99-99'
                                autoComplete='tel'
                                name='phone'
                                placeholder='Телефон'
                            />
                            <textarea placeholder='Коментарий' name='comment'></textarea>
                            <label>
                                <input
                                    type='checkbox'
                                    checked={isAgree}
                                    onChange={({ target }) => setAgree(target.checked)}
                                />
                                Я даю согласие на{' '}
                                <Link to={'/documents?block=policy'}>
                                    обработку персональных данных
                                </Link>
                            </label>
                            <button disabled={!isAgree || loading} type='submit'>
                                Хочу быть богатым!
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    )
}
